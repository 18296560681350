Home = {
    init: function() {
        this.initBlockReveal();
        this.initScrollEffects();
    },
    initBlockReveal: function() {
    	
    	// Block reveal effect to first elements
		var scrollElemToWatch_1 = document.getElementById('rev-3'),
			watcher_1 = scrollMonitor.create(scrollElemToWatch_1, -300),				
			rev3 = new RevealFx(scrollElemToWatch_1, {
				revealSettings : {
					bgcolor: '#ed1247',
					direction: 'rl',
					onCover: function(contentEl, revealerEl) {
						contentEl.style.opacity = 1;
					}
				}
			});

		var scrollElemToWatch_2 = document.getElementById('rev-4'),
			watcher_2 = scrollMonitor.create(scrollElemToWatch_2, -300),				
			rev4 = new RevealFx(scrollElemToWatch_2, {
				revealSettings : {
					bgcolor: '#000000',
					direction: 'rl',
					onCover: function(contentEl, revealerEl) {
						contentEl.style.opacity = 1;
					}
				}
			});

		var scrollElemToWatch_3 = document.getElementById('rev-5'),
			watcher_3 = scrollMonitor.create(scrollElemToWatch_3, -300),				
			rev5 = new RevealFx(scrollElemToWatch_3, {
				revealSettings : {
					bgcolor: '#5d00aa',
					direction: 'rl',
					onCover: function(contentEl, revealerEl) {
						contentEl.style.opacity = 1;
					}
				}
			});

		var scrollElemToWatch_4 = document.getElementById('rev-6'),
			watcher_4 = scrollMonitor.create(scrollElemToWatch_4, -300),				
			rev6 = new RevealFx(scrollElemToWatch_4, {
				revealSettings : {
					bgcolor: '#fe433e',
					direction: 'rl',
					onCover: function(contentEl, revealerEl) {
						contentEl.style.opacity = 1;
					}
				}
			});


		watcher_1.enterViewport(function() {
			rev3.reveal();
			rev6.reveal();
			watcher_1.destroy();
			watcher_4.destroy();
		});

		watcher_3.enterViewport(function() {
			rev4.reveal();
			rev5.reveal();
			watcher_2.destroy();
			watcher_3.destroy();
		});


		// BG Change White->Black
		var scrollElemToWatch_5 = document.getElementById('content'),
			watcher_5 = scrollMonitor.create(scrollElemToWatch_5, -$('#fixed').height());

		//watcher_5.lock(); // ensure that we're always watching the place the element originally was
		watcher_5.enterViewport(function() {
			$('body').removeClass('bg-white');
		});
		watcher_5.exitViewport(function() {
			$('body').addClass('bg-white');
		});


		// Add block effect to ajax loaded elements
		$.fn.almComplete = function(alm){

			$.each(alm.data, function( index, value ) {
				var bgColor = $(value).find('.hashtags').css('backgroundColor');
				index = new RevealFx(value.firstChild.nextElementSibling, {
					revealSettings : {
						bgcolor: bgColor,
						direction: 'rl',
						onCover: function(contentEl, revealerEl) {
							contentEl.style.opacity = 1;
						}
					}
				});
				index.reveal();
			});
		};	
    },
    initScrollEffects: function() {

	  $('a.scroller[href*="#"]:not([href="#"])').click(function() {
	    if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
	      var target = $(this.hash);
	      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
	      if (target.length) {
	        $('html, body').animate({
	          scrollTop: target.offset().top
	        }, 500);
	        return false;
	      }
	    }
	  });
    }
};