// Functions
Common = {
    init: function() {
        this.initPlaceholders();
        this.initGA();
        //this.toggleMenu();
    },
    initPlaceholders: function() {
       
        /* IE9 placeholder support */
        $('input, textarea').placeholder();
    },
    toggleMenu: function() {
        
        // Toggle Main Menu
        $('.js-toggle-menu').on('click', function(){
            $(this).find('span').toggleClass('btn-open').toggleClass('btn-open btn-close');
        });
    },
    initGA: function() {
        $('.main-nav__list a').on('click', function() {
            ga('send', 'event', 'Avaleht - Menüü', 'click', $(this).text());
        });
        $(document).on('click', '.work__link', function() {
            ga('send', 'event', 'Avaleht - Tehtud tööd', 'click', $(this).attr('href'));
        });
        $('#load-more').on('click', function() {
            ga('send', 'event', 'Avaleht - Tehtud tööd', 'click', 'Rohkem töid');
        });
        $('.contact-form__btn').on('click', function() {
           ga('send', 'event', 'Avaleht - Kontakt', 'click', 'Saada kiri');
        });
        $('.footer-contacts__list a').on('click', function() {
           ga('send', 'event', 'Avaleht - Footer', 'click', 'info@veeb.ee');
        });
        $('.arrow.bounce').on('click', function() {
    	   ga('send', 'event', 'Avaleht - Header', 'click', 'keri alla nool');
        });     
    }
};